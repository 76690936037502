@charset "utf-8";

/* stylelint-disable-next-line value-keyword-case */
$family-sans-serif: BlinkMacSystemFont, -apple-system, "Segoe UI", "Ubuntu", "Roboto", "Oxygen", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;

$black: hsl(0, 0%, 0%);
$green: hsl(141, 42%, 53%);
$cyan: hsl(217, 89%, 61%);
$blue: hsl(208, 79%, 51%);
$red: hsl(348, 100%, 61%);

/** Bulma: Variables **/
@import "../../node_modules/bulma/sass/utilities/initial-variables.sass";
@import "../../node_modules/bulma/sass/utilities/functions.sass";
@import "../../node_modules/bulma/sass/utilities/derived-variables.sass";

$scheme-main: $black;
$text: $white-ter;

$strong-color: $grey-lighter;

$link-hover: $link-light;

$hr-background-color: $grey-light;

$border-hover: $link;
$input-focus-border-color: $link-light;

$title-color: $white-ter;
$subtitle-color: $grey-lightest;
$subtitle-strong-color: $grey-lightest;

$input-background-color: $scheme-main;
$input-border-color: $grey;
$input-color: $text;
$input-placeholder-color: $grey-lightest;

$input-disabled-background-color: $grey-dark;
$input-disabled-border-color: $input-border-color;
$input-disabled-color: $input-color;
$input-disabled-placeholder-color: $grey-lightest;

$pagination-background-color: $scheme-main;
$pagination-border-color: $text;
$pagination-color: $text;

$pagination-hover-background-color: $pagination-color;
$pagination-hover-border-color: $pagination-border-color;
$pagination-hover-color: $scheme-main;

$pagination-disabled-background-color: $grey-dark;
$pagination-disabled-border-color: $grey;
$pagination-disabled-color: hsl(0, 0%, 64%);

$tabs-link-color: $text;
$tabs-link-hover-color: $tabs-link-color;
$tabs-boxed-link-active-border-color: $grey;
$tabs-boxed-link-hover-background-color: $grey-darker;
$tabs-boxed-link-hover-border-bottom-color: $tabs-boxed-link-active-border-color
